.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.contact-page img {
    border-radius: 50%;
    height: 250px;
}

.contact-info h4 {
    font-size: 30px;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.contact-list {
    font-weight: 400;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin: 15px;
    color: black;
}

.contact-icon {
    margin-right: 15px;
}

a.nostyle:link {
    text-decoration: inherit;
    cursor: pointer;
}

a {
    color: black;
}