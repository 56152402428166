.project-wrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  top: -65px;
}

.middle-bar {
  width: 2px;
  min-height: 100%;
  background-color: #585858;
  
}

.left-side,
.right-side {
  width: 50%;
  margin: 50px;
}

.left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
    height: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.project-wrapper p {
    font-size: 20px;
    color: black;
    text-align: center;
    margin-bottom: 10px;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.icons {
    font-size: 40px;
    margin: 20px;
    display: flex;
    gap: 100px;
}

.church-title {
  max-width: 400px;
}

h3 {
    font-size: 35px;
    margin-bottom: 25px;
    text-decoration: underline;
}

.grow { 
    transition: all .2s ease-in-out; 
}
    
.grow:hover { 
    transform: scale(1.15); 
    cursor: pointer;
    color: black;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 10px; */

}

a {
    color: #585858;
}

.ohfm2 {
    margin-top: 275px;
}

.project h3 {
  text-align: center;
}


@media screen and (max-width: 965px) {
  .project-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .middle-bar {
    display: none;
  }
  .omhf {
    margin-top: 0;
  }
  .left-side {
    margin-bottom: 0px;
  }
  .right-side {
    margin-top: 0px;
  }
 
}