.now-title {
    font-size: 23px;
    margin-left: 30px;
}

.now-section h4 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.now-section {
    margin: 0 50px;
}

#now-p {
    font-size: 20px;
    
}

.now {
    padding: 50px 0;
    max-width: 750px;
}

.updated {
    margin-left: 50px;
    margin-bottom: 30px;
}
