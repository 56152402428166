.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 80px;
    height: 55px;
    background-color: white;
    align-items: center;
    /* make a 'scrolling' class making the shadow bigger when scrolling */
    box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
    box-shadow: 0 -6px 5px 5px rgba(0,0,0,0.5);
    position: sticky; top: 0;
    z-index: 999;
}

.header * {
    font-size: 25px;
}

.navlinks {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-bars {
    height: 25px;
    border-right: 2px solid gray;
    margin: 0 15px;
}

.burger {
    display: none;
    cursor: pointer;
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: gray;
    margin: 5px;
}

/* .hidden {
    display: none;
    
} */

@media screen and (max-width: 650px) {
    body {
        overflow-x: hidden;
    }

    .navlinks {
       position: absolute;
       right: 0px;
       height: calc(100vh - 55px);
       top: 55px;
       background-color: rgb(255, 255, 255);
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;       
       width: 50%;
       transform: translateX(100%);
       transition: transform 0.5s ease-in;
       border-left: solid lightgray 1px;
       box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
       clip-path: inset(0px -10px);
    }

    .burger {
        display: block;
    }

    .header-bars {
        opacity: 0;
        margin: 10px 0px;
    }
}

.burger-active {
    transform: translateX(0%);
}

.burger-x .line1 {
    transform: rotate(-45deg) translate(-5px,6px);
}

.burger-x .line2 {
    opacity: 0;
}

.burger-x .line3 {
    transform: rotate(45deg) translate(-5px,-6px);
}