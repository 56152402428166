.hero-page {
    height: calc(100vh - 55px);
    min-height: 525px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.hero-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 80%;
    align-items: center;
    position: relative;
    top: -40px;
}

.hero-info {
    width: 50%;
    text-align: right;
    height: fit-content;
}

.hero-title {
    width: 50%;
    height: fit-content;
}

p {
    color: #585858;
    font-size: 33px;
}

h2 {
    font-size: 55px;
    margin-bottom: 4px;
}

.arrow {
    text-align: center;
    position: relative;
    top: -125px
}

.arrow * {
    font-size: 22px;
    opacity: 75%;
}

.arrow p {
    margin-bottom: 10px;
}

/** add functionality to fade in and move arrow */

@media screen and (max-width: 100px) {
    .arrow {
        top: -75px;
      }
}

@media screen and (max-width: 960px) {
    .hero-container {
        flex-direction: column;
       
    }

    .arrow {
        top: 0;
        margin-bottom: 50px;
    }
    .hero-page {
        height: 200vh;
        justify-content: start;
        height: fit-content
    }
    .hero-title {
        margin-top: 200px;
        margin-bottom: 100px;
        width: 100%;
        
    }
    .hero-info {
        text-align: center;
        width: 100%;
    }
   

}

